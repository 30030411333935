const routes = [
  {
    path: "/finance/consolidation",
    name: "financial-consolidation-report",
    component: () => import("@/views/finance/ConsolidationReport"),
    meta: {
      layout: "content",
      resource: "finance",
      action: "manage",
    },
  },
  {
    path: "/finance/list-consolidated-ca",
    name: "finance-list-site-ca",
    component: () => import("@/views/finance/SiteConsolidatedCa"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/finance/upload-consolidated-ca",
    name: "finance-set-site-ca",
    component: () => import("@/views/finance/UploadSiteConsolidatedCa"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
];

export default routes;
