import { mdiCurrencyEur } from "@mdi/js";

const Nav = [
  {
    title: "Conso financière",
    icon: mdiCurrencyEur,
    resource: "finance",
    action: "manage",
    children: [
      {
        title: "Rapport mensuel",
        resource: "finance",
        action: "manage",
        to: "financial-consolidation-report",
      },
      {
        title: "CA consolidé",
        to: "finance-list-site-ca",
        resource: "all",
        action: "manage",
      },
      {
        title: "Upload CA consolidé",
        to: "finance-set-site-ca",
        resource: "all",
        action: "manage",
      },
    ],
  },
];

export default Nav;
