<template>
  <layout-blank>
    <v-snackbar
      v-model="snackBar['show']"
      :timeout="snackBar['timeout']"
      :color="snackBar['color']"
      top
    >
      <div v-html="snackBar.text"></div>
    </v-snackbar>
    <slot></slot>
  </layout-blank>
</template>

<script>
import LayoutBlank from "@/@core/layouts/variants/blank/LayoutBlank.vue";
import { computed } from "@vue/composition-api";
import store from "@/store";

export default {
  setup() {
    const snackBar = computed(() => store.getters["app/getSnackBar"]);
    return { snackBar };
  },

  components: {
    LayoutBlank,
  },
};
</script>

<style></style>
