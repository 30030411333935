import { mdiCart } from "@mdi/js";
const ctcNav = [
  {
    title: "Content to Commerce",
    icon: mdiCart,
    children: [
      {
        title: "Dashboard",
        to: "c2c-dashboard",
        resource: "ctc",
        action: "manage",
      },
      {
        title: "GA liste d'URLs",
        to: "c2c-ga-url-list",
        resource: "ctc",
        action: "manage",
      },
      /*
      {
        title: "Stats par Site",
        to: "c2c-sites",
        resource: "ctc",
        action: "manage",
      },
      {
        title: "Créer un Article",
        to: "c2c-post-create",
        resource: "ctc",
        action: "manage",
      },
      {
        title: "Liste des Articles",
        to: "c2c-post-list",
        resource: "ctc",
        action: "manage",
      },
      {
        title: "Clicrefs manquants",
        to: "c2c-missing-clicrefs",
        resource: "ctc",
        action: "manage",
      },
      {
        title: "Dates remplies",
        to: "c2c-filled-dates",
        resource: "all",
        action: "manage",
      },
      {
        title: "Matching de sites",
        to: "c2c-admin-sites-matching",
        resource: "ctc",
        action: "manage",
      },
      */
    ],
  },
];

export default ctcNav;
