<template>
  <v-menu
    offset-y
    left
    nudge-bottom="22"
    content-class="list-style notification-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-if="showBadgeAlert"
        v-bind="attrs"
        v-on="on"
        class="mdi-bell-badge-outline"
      >
        {{ icons.mdiBellBadgeOutline }}
      </v-icon>
      <v-icon v-else v-bind="attrs" v-on="on">
        {{ icons.mdiBellOutline }}
      </v-icon>
    </template>

    <v-card
      v-show="siteMatchingCount > 0"
      class="app-bar-notification-content-container mb-1"
    >
      <v-list class="py-0">
        <v-list-item link>
          <v-list-item-content class="d-block">
            <router-link
              to="/premium/sites-matching"
              style="text-decoration: none; color: inherit"
            >
              <v-list-item-title class="text-sm text--secondary">
                Site matching
              </v-list-item-title>
            </router-link>
          </v-list-item-content>
          <v-list-item-action>
            <span class="red--text text-xs">{{ siteMatchingCount }}</span>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
    <v-card class="app-bar-notification-content-container">
      <perfect-scrollbar
        class="ps-user-notifications"
        :options="perfectScrollbarOptions"
      >
        <v-list class="py-0">
          <!-- Header -->
          <v-list-item class="d-flex" link>
            <div class="d-flex align-center justify-space-between flex-grow-1">
              <span class="font-weight-semibold">Rapports</span>
              <v-chip
                class="v-chip-light-bg primary--text font-weight-semibold"
                small
              >
                {{ count_unread }}
              </v-chip>
            </div>
          </v-list-item>
          <v-divider></v-divider>

          <!-- Notifications -->
          <template v-for="(report, index) in reports">
            <v-list-item :key="report.title" link>
              <!-- Content -->
              <v-list-item-content class="d-block">
                <router-link
                  :to="report.url"
                  style="text-decoration: none; color: inherit"
                >
                  <v-list-item-title
                    class="text-sm"
                    :class="{ 'font-weight-semibold': !report.read_status }"
                  >
                    {{ report.name }}
                  </v-list-item-title>
                </router-link>
              </v-list-item-content>

              <!-- Item Action/Time -->
              <v-list-item-action>
                <span class="text--secondary text-xs">{{ report.date }}</span>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="index"></v-divider>
          </template>
          <v-list-item key="read-all-btn" class="read-all-btn-list-item">
            <v-btn
              block
              color="primary"
              @click="$router.push('/alert-system/')"
            >
              Voir tous mes rapports
            </v-btn>
          </v-list-item>
        </v-list>
      </perfect-scrollbar>
    </v-card>
  </v-menu>
</template>

<script>
import { mdiBellOutline, mdiBellBadgeOutline } from "@mdi/js";
import { getInitialName } from "@core/utils";

// 3rd Party
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import axios from "@axios";

import { getCurrentInstance } from "@vue/composition-api";

export default {
  components: {
    PerfectScrollbar,
  },
  async created() {
    let { reports, count_unread } = await this.getReports();
    this.reports = reports;
    this.count_unread = count_unread;

    const abilities = JSON.parse(localStorage.getItem("userAbility"));
    const allowedAbilities = ["all", "general", "programmatique"];
    if (
      abilities &&
      abilities.some((ability) => allowedAbilities.includes(ability.subject))
    ) {
      this.getSiteMatchingUnmatchedCount();
    }
  },
  data() {
    return {
      perfectScrollbarOptions: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },

      icons: {
        mdiBellOutline,
        mdiBellBadgeOutline,
      },
      reports: [],
      count_unread: 0,
      siteMatchingCount: 0,
    };
  },
  methods: {
    getInitialName,
    async getReports() {
      const reportsData = await axios.get("/alert-system-generated-reports/", {
        params: {
          limit: 5,
        },
      });
      const reports = reportsData.data.reports;
      const count_unread = reportsData.data.count_unread;
      reports.map((report) => {
        report.url = `/alert-system/${report.id}/`;
      });
      return { reports, count_unread };
    },
    async getSiteMatchingUnmatchedCount() {
      try {
        const { data } = await axios.get("/partner_sites/unmatched", {
          params: {
            count_only: true,
          },
        });
        this.siteMatchingCount = data.total;
      } catch (error) {
        console.warn("getSiteMatchingUnmatchedCount error:", error);
      }
    },
  },
  computed: {
    showBadgeAlert() {
      return this.siteMatchingCount > 0;
    },
  },
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/styles.sass";

.app-bar-notification-content-container {
  .read-all-btn-list-item {
    padding-top: 14px;
    padding-bottom: 14px;
    min-height: unset;
  }
}

.ps-user-notifications {
  max-height: calc(var(--vh, 1vh) * 80);
}

.notification-menu-content {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-width: calc(100vw - (1.5rem * 2)) !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
}
/*.mdi-bell-badge-outline > svg {fill: red;stroke: red;}*/
.mdi-bell-badge-outline {
  position: relative;
}
.mdi-bell-badge-outline::after {
  content: "" !important;
  position: absolute !important;
  width: 6px !important;
  height: 6px !important;
  background-color: red !important;
  border-radius: 50% !important;
  top: 3px !important;
  right: 3px !important;
  z-index: 1 !important;
  opacity: 1 !important;
  left: auto !important;
}
</style>
