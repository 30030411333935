const routes = [
  {
    path: "/alert-system",
    name: "alert-system",
    component: () =>
      import("@/views/pages/admin/report-alert/GeneratedAlertsList.vue"),
    meta: {
      layout: "content",
      resource: "admin",
      action: "manage",
    },
  },
  {
    path: "/alert-system/:id",
    name: "alert-system-edit",
    component: () =>
      import("@/views/pages/admin/report-alert/GeneratedAlertView.vue"),
    meta: {
      layout: "content",
      resource: "admin",
      action: "manage",
    },
  },
  // Admin Routes
  {
    path: "/admin/edit-group-site/:id?",
    name: "admin-edit-group-site",
    component: () =>
      import("@/views/pages/admin/group-site/GroupSiteCreateOrUpdate"),
    meta: {
      layout: "content",
      resource: "admin",
      action: "manage",
    },
  },
  {
    path: "/admin/group-site-list",
    name: "admin-list-group-site",
    component: () => import("@/views/pages/admin/group-site/GroupSiteList"),
    meta: {
      layout: "content",
      resource: "admin",
      action: "manage",
    },
  },
  {
    path: "/admin/edit-partner-view/:id?",
    name: "admin-edit-partner-view",
    component: () =>
      import("@/views/pages/admin/partner-view/PartnerViewCreateOrUpdate"),
    meta: {
      layout: "content",
      resource: "admin",
      action: "manage",
    },
  },
  {
    path: "/admin/partner-view-list",
    name: "admin-list-partner-view",
    component: () => import("@/views/pages/admin/partner-view/PartnerViewList"),
    meta: {
      layout: "content",
      resource: "admin",
      action: "manage",
    },
  },
  {
    path: "/admin/edit-group-partner-view/:id?",
    name: "admin-edit-group-partner-view",
    component: () =>
      import(
        "@/views/pages/admin/group-partner-view/GroupPartnerViewCreateOrUpdate"
      ),
    meta: {
      layout: "content",
      resource: "admin",
      action: "manage",
    },
  },
  {
    path: "/admin/group-partner-view-list",
    name: "admin-list-group-partner-view",
    component: () =>
      import("@/views/pages/admin/group-partner-view/GroupPartnerViewList"),
    meta: {
      layout: "content",
      resource: "admin",
      action: "manage",
    },
  },
  // Admin / Sites :
  {
    path: "/admin/edit-site/:id?",
    name: "admin-edit-site",
    component: () => import("@/views/admin/site/SiteCreateOrUpdate"),
    meta: {
      layout: "content",
      resource: "admin",
      action: "manage",
    },
  },
  {
    path: "/admin/list-site",
    name: "admin-list-site",
    component: () => import("@/views/admin/site/SiteList"),
    meta: {
      layout: "content",
      resource: "admin",
      action: "manage",
    },
  },
  // Admin / Partenaires :
  {
    path: "/admin/partners",
    name: "admin-partners",
    component: () => import("@/views/admin/partner/List.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/admin/partner/edit/:id?",
    name: "admin-partner-edit",
    component: () => import("@/views/admin/partner/Edit.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/admin/partner/accounts",
    name: "admin-partner-accounts",
    component: () => import("@/views/admin/partner_account/List.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/admin/partner/account/edit/:id?",
    name: "admin-partner-account-edit",
    component: () => import("@/views/admin/partner_account/Edit.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/partner/data",
    name: "admin-partner-data",
    component: () => import("@/views/admin/partner_data/PartnerData.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/admin/partner/relations",
    name: "admin-partner-relations",
    component: () => import("@/views/admin/partner_relation/List.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/admin/partner/relation/edit/:id?",
    name: "admin-partner-relation-edit",
    component: () => import("@/views/admin/partner_relation/Edit.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/admin/create-email-report",
    name: "admin-create-email-report",
    component: () =>
      import("@/views/pages/admin/email-report/EmailReportCreate"),
    meta: {
      layout: "content",
      resource: "admin",
      action: "manage",
    },
  },
  {
    path: "/admin/list-email-report",
    name: "admin-list-email-report",
    component: () => import("@/views/pages/admin/email-report/EmailReportList"),
    meta: {
      layout: "content",
      resource: "admin",
      action: "manage",
    },
  },
  {
    path: "/admin/create-alert-report",
    name: "admin-create-alert-report",
    component: () =>
      import("@/views/pages/admin/report-alert/ReportAlertCreate"),
    meta: {
      layout: "content",
      resource: "admin",
      action: "manage",
    },
  },
  {
    path: "/admin/list-alert-report",
    name: "admin-list-alert-report",
    component: () => import("@/views/pages/admin/report-alert/ReportAlertList"),
    meta: {
      layout: "content",
      resource: "admin",
      action: "manage",
    },
  },
  {
    path: "/admin/workflows",
    name: "admin-aiflow-start-dag",
    component: () => import("@/views/pages/admin/Airflow.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/admin/marmiton-ingredient-groups",
    name: "admin-marmiton-list-ingredient-groups",
    component: () => import("@/views/admin/marmiton/IngredientGroups.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/admin/marmiton-create-ingredient-group",
    name: "admin-marmiton-create-ingredient-group",
    component: () => import("@/views/admin/marmiton/CreateOrUpdate.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/admin/marmiton-edit-ingredient-group/:id",
    name: "admin-marmiton-edit-ingredient-group",
    component: () => import("@/views/admin/marmiton/CreateOrUpdate.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
];

export default routes;
