import axios from "@axios";
import { update } from "lodash";
export default {
  namespaced: true,
  state: {
    sites: [],
    devices: [],
    metrics: [],
    pageTypes: [],
    pageTypeList: [],
    evolutions: [],
    siteGroups: [],
    data: [],
  },
  getters: {
    getSites: (state) => state.sites,
    getMetrics: (state) => state.metrics,
    getPageTypes: (state) => state.pageTypes,
    getPageTypeList: (state) => state.pageType,
    getEvolutions: (state) => state.evolutions,
    getSiteGroups: (state) => state.siteGroups,
    getDevices: (state) => state.devices,
    getData: (state) => state.data,
  },
  mutations: {
    updateSites(state, payload) {
      state.sites = payload;
    },
    updateMetrics(state, payload) {
      state.metrics = payload;
    },
    updatePageTypes(state, payload) {
      state.pageTypes = payload;
    },
    updatePageTypeList(state, payload) {
      state.pageTypeList = payload;
    },
    updateEvolutions(state, payload) {
      state.evolutions = payload;
    },
    updateSiteGroups(state, payload) {
      state.siteGroups = payload;
    },
    updateData(state, payload) {
      state.data = payload;
    },
    updateDevices(state, payload) {
      state.devices = payload;
    },
  },
  actions: {
    updateSites({ commit }, payload) {
      commit("updateSites", payload);
    },
    updateMetrics({ commit }, payload) {
      commit("updateMetrics", payload);
    },
    updatePageTypes({ commit }, payload) {
      commit("updatePageTypes", payload);
    },
    updateEvolutions({ commit }, payload) {
      commit("updateEvolutions", payload);
    },
    async updateSiteGroups({ commit }, payload) {
      commit("updateSiteGroups", payload);
      const { data } = await axios.get("/site-groups/sites", {
        params: { group_ids: payload.join(",") },
      });
      // get site_ids only and remove duplicates
      const site_ids = data
        .map((x) => x["site_id"])
        .sort()
        .filter((item, pos, ary) => {
          return !pos || item !== ary[pos - 1];
        });

      commit("updateSites", site_ids);

      return site_ids;
    },
    updateData({ commit }, payload) {
      commit("updateData", payload);
    },
    updateDevices({ commit }, payload) {
      commit("updateDevices", payload);
    },
    async getPageTypeList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/web-perf/content-types", {});
      // console.log(data);
      commit("updatePageTypeList", items);
      return items;
    },
  },
};
