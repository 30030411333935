import Vue from "vue";
import VueRouter from "vue-router";
import { canNavigate, _ } from "@/plugins/acl/routeProtection";
import generalRoutes from "@/router/general";
import ctcRoutes from "@/router/ctc";
import programmatiqueRoutes from "@/router/programmatique";
import audienceRoutes from "@/router/audience";
import arbitrageRoutes from "@/router/arbitrage";
import seoRoutes from "@/router/seo";
import regieRoutes from "@/router/regie";
import adminRoutes from "@/router/admin";
import crmRoutes from "@/router/crm";
import financeRoutes from "@/router/finance";
import googleDiscoverRoutes from "@/router/googleDiscover";
import rmrAnalyticsRoutes from "@/router/rmrAnalytics";
import usersRoutes from "@/router/users";
import devRoutes from "@/router/dev";
import webPerfRoutes from "@/router/webPerf";

Vue.use(VueRouter);

const routes = [
  ...generalRoutes,
  ...ctcRoutes,
  ...programmatiqueRoutes,
  ...audienceRoutes,
  ...regieRoutes,
  ...devRoutes,

  ...arbitrageRoutes,
  ...seoRoutes,
  ...googleDiscoverRoutes,
  ...rmrAnalyticsRoutes,
  ...financeRoutes,
  ...adminRoutes,
  ...crmRoutes,
  ...usersRoutes,
  ...webPerfRoutes,
  {
    path: "/marmiton/visitors-info",
    name: "marmiton-visitors",
    component: () => import("@/views/regie/VisitorsInfo.vue"),
    props: {
      department: "marmiton",
    },
    meta: {
      layout: "content",
      resource: "marmiton",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "marmiton-visitors-info",
        eventCategory: "page-view",
      },
    },
  },

  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/Login.vue"),
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: () => import("@/views/pages/Notifications.vue"),
    meta: {
      layout: "content",
    },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("@/views/pages/static/PrivacyPolicy.vue"),
    meta: {
      layout: "content",
    },
  },
  {
    path: "/public-terms-of-service",
    name: "public-terms-of-service",
    component: () => import("@/views/pages/static/PublicTermsOfService.vue"),
    meta: {
      layout: "content",
    },
  },
  {
    path: "/ga-via-js-test",
    name: "ga-via-js-test",
    component: () => import("@/views/pages/static/GaViaJsTest.vue"),
    meta: {
      layout: "content",
    },
  },
  {
    path: "/test",
    name: "test",
    component: () =>
      import("@/views/pages/admin/partner-view/components/DimensionInline"),
    meta: {
      layout: "content",
    },
  },
  {
    path: "/not-authorized",
    name: "not-authorized",
    component: () => import("@/views/NotAuthorized.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
    },
  },
  {
    path: "*",
    redirect: "error-404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
      //Or for Vue 3:
      //return {el: to.hash}
    }

    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
});

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  if (
    to.name === "auth-login" ||
    to.name === "not-authorized" ||
    to.name === "notifications" ||
    to.name === "alert-system-edit"
  ) {
    return next();
  }

  const isLoggedIn =
    localStorage.getItem("accessToken") && localStorage.getItem("userAbility");

  if (!canNavigate(to)) {
    if (!isLoggedIn)
      return next({
        name: "auth-login",
      });

    return next({ name: "not-authorized" });
  }

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next("/");
  }

  return next();
});

export const getRoute = (name) => {
  const route = routes.find((r) => r.name === name);

  return route?.path ?? "/";
};

export default router;
